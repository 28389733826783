




















import { ResourceTopic } from '@/models';
import { defineComponent, onMounted, reactive, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'ViewTopicsPage',
  setup() {
    const creating = ref(false);
    const topics = ref<ResourceTopic[]>([]);
    const { router } = useRouter();

    const formData = reactive({
      name: '',
    });

    const headers = [
      { text: 'Name', value: 'data.name' },
      { text: '', value: 'actions' },
    ];

    const create = async () => {
      const topic = await ResourceTopic.create(formData);

      if (topic) {
        formData.name = '';
        creating.value = false;
      }

      await loadTopics();
    };
    const loadTopics = async () => {
      topics.value = await ResourceTopic.getAll();
    };

    const goToItem = (item: ResourceTopic) => {
      router.push({ name: routeNames.practitioner.resources.topics.view, params: { uuid: item.uuid } });
    };

    onMounted(() => {
      loadTopics();
    });

    return {
      topics,
      headers,
      goToItem,
      formData,
      create,
      creating,
    };
  },
});
